import http from "../../helpers/http-common";

class StatsDataService {
    getAll() {
        return http.get("/stats");
    }

    getCurrentOnline() {
        return http.get(`/stats/online`);
    }

    getLogged(filter) {
        let query = this.createQuery(filter);
        return http.get(`/stats/logged?${query.slice(0, -1)}`);
    }

    getUniqueAccess(filter) {
        let query = this.createQuery(filter);
        return http.get(`/stats/unique-access?${query.slice(0, -1)}`);
    }

    getRegistered(filter) {
        let query = this.createQuery(filter);
        return http.get(`/stats/registered?${query.slice(0, -1)}`);
    }

    createQuery(filter) {
        let query = '';
        for (const property in filter) {
            if (filter[property]) {
                if (property == 'range') query += `start=${filter[property][0]}&end=${filter[property][1]}&`
                else query += `${property}=${filter[property]}&`
            }
        }
        return query;
    }
}

export default new StatsDataService();
