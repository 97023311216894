<template>

<div>
    <div class="top-content ">
        <span class="h4">Statistiche accesso prodotti</span>
        <br>
        <span class="small text-muted">Dati presi a partire dal {{ dataCollectionStartDate }}.</span>
        <br>
        <br>
        <div class="row">

            <div class="col-md-3">
                <b-form-group id="productId" label="" label-for="productId" description="">
                    <b-form-select v-model="filter.productId" @change="retrieveStats()">
                        <option value="0">- Tutti i prodotti -</option>
                        <option v-for="product in products" v-bind:value="product.id" :key="product.id">
                            {{ product.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>

            <div class="col-md-3">
                <b-form-group id="schooldId" label="" label-for="schooldId" description="">
                    <b-form-select v-model="filter.schoolId" @change="retrieveStats()">
                        <option value="0">- Tutte le scuole -</option>
                        <option v-for="school in schools" v-bind:value="school.id" :key="school.id">
                            {{ school.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>

            <div class="col-md-5">
                <date-picker v-model="filter.range" range :lang="lang" value-type="format" @change="retrieveStats()"></date-picker>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="index">
            <div class="card">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col" class="text-center">Prima apertura</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in productsAccess"
                        :key="item.id"
                    >
                        <td>{{ item.product_name }}</td>
                        <td class="text-center">{{ item.number }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>

</template>

<script>
import StatsDataService from "@/components/stats/StatsDataService";
import moment from 'moment';
import ProductLicenseTemplateDataService from "@/components/product_license_template/ProductLicenseTemplateDataService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';
import EduLabDataService from "@/components/product_license_template/EduLabDataService";
import SchoolDataService from "@/components/school/SchoolDataService";

export default {
    name: "stats-product-access",
    components: {DatePicker},
    data() {
        return {
            schools: [],
            products: [],
            productsAccess: [],
            filter: {
                productId: 0,
                schoolId: 0,
                range: null
            },
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: true,
            }
        };
    },
    props: ["dataCollectionStartDate"],
    methods: {
        retrieveStats() {
            EduLabDataService.getProductAccess(this.filter)
                .then(response => response.json())
                .then(data => {
                    this.productsAccess = data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

    },
    mounted() {
        SchoolDataService.getAll()
            .then(response => {
                this.schools = response.data;
            })
            .catch(e => {
                console.log(e);
            });

        EduLabDataService.getAllProducts(this.filter)
            .then(response => response.json())
            .then(data => {
                this.products = data;
            })
            .catch(e => {
                console.log(e);
            });
        // set range default to last week
        const endDate = moment().format('YYYY-MM-DD');
        const startDate = moment().subtract(7, 'day').format('YYYY-MM-DD');
        this.filter.range = [startDate, endDate];
        this.retrieveStats();
    }
};
</script>
