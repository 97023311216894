<template>

    <div>
        <div class="top-content ">
            <span class="h4">Statistiche accesso utenti</span>
            <br>
            <span class="small text-muted">Dati presi a partire dal {{ dataCollectionStartDate }}, fatta eccezione per gli utenti registrati.</span>
            <br>
            <br>
            <div class="row">

                <div class="col-md-4">
                    <b-form-group id="templateId" label="" label-for="templateId" description="">
                        <b-form-select v-model="filter.schoolId" @change="retrieveStats()">
                            <option value="0">- Tutte le scuole -</option>
                            <option v-for="school in schools" v-bind:value="school.id" :key="school.id">
                                {{ school.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div class="col-md-4">
                    <date-picker v-model="filter.range" range :lang="lang" value-type="format" @change="retrieveStats()"></date-picker>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="index">
                <div class="card">
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">EduLab</th>
                                    <th scope="col" class="text-center">N.</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Accessi effettuati</td>
                                    <td class="text-center">{{ loggedEdulab }}</td>
                                </tr>
                                <tr>
                                    <td>Utenti univoci</td>
                                    <td class="text-center">{{ uniqueEdulab }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">EduGest</th>
                                    <th scope="col" class="text-center">N.</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Accessi effettuati</td>
                                    <td class="text-center">{{ loggedEdugest }}</td>
                                </tr>
                                <tr>
                                    <td>Utenti univoci</td>
                                    <td class="text-center">{{ uniqueEdugest }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Globale</th>
                            <th scope="col" class="text-center">N.</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Utenti registrati</td>
                            <td class="text-center">{{ registered }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import StatsDataService from "@/components/stats/StatsDataService";
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';
import SchoolDataService from "@/components/school/SchoolDataService";
import EduLabDataService from "@/components/product_license_template/EduLabDataService";

export default {
    name: "stats-user-access",
    components: {DatePicker},
    data() {
        return {
            schools: [],
            loggedEdulab: 0,
            loggedEdugest: 0,
            uniqueEdulab: 0,
            uniqueEdugest: 0,
            registered: 0,
            filter: {
                schoolId: 0,
                range: null
            },
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: true,
            }
        };
    },
    props: ['dataCollectionStartDate'],
    methods: {
        retrieveStats() {
            this.registered = 0;
            StatsDataService.getLogged(this.filter)
                .then(response => {
                    this.loggedEdugest = response.data.length;
                })
                .catch(e => {
                    console.log(e);
                });
            EduLabDataService.getLogged(this.filter)
                .then(response => response.json())
                .then(data => {
                    this.loggedEdulab = data.length;
                })
                .catch(e => {
                    console.log(e);
                });
            StatsDataService.getUniqueAccess(this.filter)
                .then(response => {
                    this.uniqueEdugest = response.data.length;
                })
                .catch(e => {
                    console.log(e);
                });
            EduLabDataService.getUniqueAccess(this.filter)
                .then(response => response.json())
                .then(data => {
                    this.uniqueEdulab = data.length;
                })
                .catch(e => {
                    console.log(e);
                });
            StatsDataService.getRegistered(this.filter)
                .then(response => {
                    this.registered += response.data.length;
                })
                .catch(e => {
                    console.log(e);
                });
            EduLabDataService.getRegistered(this.filter)
                .then(response => response.json())
                .then(data => {
                    this.registered += data.length;
                })
                .catch(e => {
                    console.log(e);
                });
        },

    },
    mounted() {
        SchoolDataService.getAll()
            .then(response => {
                this.schools = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        // set range default to last week
        const endDate = moment().format('YYYY-MM-DD');
        const startDate = moment().subtract(7, 'day').format('YYYY-MM-DD');
        this.filter.range = [startDate, endDate];
        this.retrieveStats();
    }
};
</script>
