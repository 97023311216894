<template>

    <div>
        <div class="top-content ">
            <span class="h4">
                <span :class="(currentOnline == 0) ? 'text-muted' : ''">Utenti online</span>
                <img src="../../assets/pulsating-dot.gif" width="25" v-if="currentOnline > 0"/>
            </span>
            <p class="text-muted" v-if="currentOnline == 0">Non ci sono utenti connessi in questo momento.</p>
            <p v-else>Ci sono <b>{{ currentOnline }}</b> utenti connessi.</p>
        </div>

    </div>

</template>

<script>
import StatsDataService from "@/components/stats/StatsDataService";
import EduLabDataService from "@/components/product_license_template/EduLabDataService";

export default {
    name: "stats-online-user",
    data() {
        return {
            currentOnline: 0,
            currentOnlineEdulab: 0,
            currentOnlineEdugest: 0,
            updateState: 0, // 0 = updating, 1 = updatedEdulab, 2 = updated Edugest
            onlineCheckInterval: null
        };
    },
    methods: {
        retrieveStats() {
            this.updateState = 0;

            StatsDataService.getCurrentOnline()
                .then(response => {
                    this.currentOnlineEdugest = response.data.length;
                    this.updateState = 2;
                })
                .catch(e => {
                    console.log(e);
                });
            EduLabDataService.getOnline()
                .then(response => response.json())
                .then(data => {
                    this.currentOnlineEdulab = data.length;
                    this.updateState = 1;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        retrieveStatsInterval() {
            this.onlineCheckInterval = setInterval(() => {
                this.retrieveStats();
            }, 5000);
        },
    },
    watch: {
        updateState(newVal, oldVal){
            if (newVal > 0) this.currentOnline = this.currentOnlineEdugest + this.currentOnlineEdulab;
        }
    },
    mounted() {
        this.retrieveStats();
        this.retrieveStatsInterval();
    },
    beforeDestroy() {
        clearInterval(this.onlineCheckInterval)
    }
};
</script>
