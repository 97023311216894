<template>

    <div>
        <div class="row section-header" >
            <div class="col-md-9 section-title">
                <h3>Statistiche</h3>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <stats-online-user />
                <hr>
                <stats-user-access :data-collection-start-date="dataCollectionStartDate" />
                <br/>
                <stats-product-access :data-collection-start-date="dataCollectionStartDate" />
                <br/>
                <stats-edulab-app :data-collection-start-date="dataCollectionStartDate" />
            </div>
            <div class="col-md-1"></div>
        </div>

    </div>

</template>

<script>
import StatsProductAccess from "@/components/stats/StatsProductAccess";
import StatsUserAccess from "@/components/stats/StatsUserAccess";
import StatsOnlineUser from "@/components/stats/StatsOnlineUser";
import StatsEdulabApp from "@/components/stats/StatsEdulabApp";

export default {
    name: "stats-view",
    components: {StatsEdulabApp, StatsOnlineUser, StatsProductAccess, StatsUserAccess},
    data() {
        return {
            dataCollectionStartDate: '24 giugno 2024'
        };
    },
    methods: {

    },
    mounted() {
    }
};
</script>

