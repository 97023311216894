<template>

    <div>
        <div class="top-content ">
            <span class="h4">Statistiche App EduLab</span>
            <br>
            <span class="small text-muted">Dati presi a partire dal {{ dataCollectionStartDate }}.</span>
            <br>
            <div class="row">

            </div>
        </div>

        <div class="content">
            <div class="index">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Piattaforma</th>
                            <th scope="col" class="text-center">N.</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Web / Desktop</td>
                            <td class="text-center">{{ webCount }}</td>
                        </tr>
                        <tr>
                            <td>iOs</td>
                            <td class="text-center">{{ iosCount }}</td>
                        </tr>
                        <tr>
                            <td>Android</td>
                            <td class="text-center">{{ androidCount }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Versioni OS*</th>
                            <th scope="col" class="text-center">N.</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in osVersions"
                            :key="item.version"
                        >
                            <td>{{ item.version }}</td>
                            <td class="text-center">{{ item.count }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p class="small text-muted"><i>* Nota: macOS dalla versione 11 non invia più la reale versione dell’OS ma la 10_15_7</i></p>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import EduLabDataService from "@/components/product_license_template/EduLabDataService";

export default {
    name: "stats-edulab-app",
    components: {},
    data() {
        return {
            appStats: [],
            webCount: 0,
            iosCount: 0,
            androidCount: 0,
            osVersions: []
        };
    },
    props: ['dataCollectionStartDate'],
    methods: {
        retrieveStats() {
            this.registered = 0;
            EduLabDataService.getAppStats()
                .then(response => response.json())
                .then(data => {
                    this.appStats = data;
                    for(let row of this.appStats) {
                        // count platforms
                        switch (row.app_build_platform) {
                            case 'Web':
                                this.webCount++;
                                break;
                            case 'iOs':
                                this.iosCount++;
                                break;
                            case 'Android':
                                this.androidCount++;
                                break;
                            default:
                                break;
                        }
                        // count versions
                        let vers = this.osVersions.find((item) => item.version == row.app_os_info)
                        if (vers) {
                            vers.count++;
                        } else {
                            this.osVersions.push({
                                version: row.app_os_info,
                                count: 1
                            })
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

    },
    mounted() {
        this.retrieveStats();
    }
};
</script>
